import React, { useEffect, useState } from 'react';
import { Alert, Badge, ButtonGroup, Card, Container, Form, Spinner, Stack, Table } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import { AnalysisButton, CopyButton, DownloadButton, LinkButton, Tooltiped } from '../Components/CommonButtons';
import CCard, { HTTPHeaders, RawPlaylist, TableCard } from '../Components/CommonCards';
import InputComponent from '../Components/InputComponent';
import { allColumns, httpGet, showPlayButton, useQueryParams } from '../utils';

function MissingStreams({ playlists, medias }) {
  const missingStreams = { video: playlists.length > 0, audio: medias.some((m) => m.TYPE === 'AUDIO') };

  return Object.entries(missingStreams)
    .filter(([_, here]) => !here)
    .map(([type, _]) => (
      // mb-0 because Alerts natively have big margin bottom, no idea why but I don't like it
      <Alert key={type} variant='danger' className='mb-0'>
        Missing {type} media playlist
      </Alert>
    ));
}

function Header({ data }) {
  const { media, asset } = useQueryParams();
  const { search } = useLocation();

  return (
    <Card border='success'>
      <Card.Header>
        HLS master playlist - <b className='text-primary'>{asset}</b>
      </Card.Header>
      <Card.Body>
        <Stack gap='2'>
          <Stack direction='horizontal' gap='1'>
            <InputComponent text='Master playlist URL'>
              <Form.Control value={media} readOnly />
            </InputComponent>
            <ButtonGroup>
              <Tooltiped text='play stream'>
                <LinkButton to={`/player${search}`} disabled={!showPlayButton(media)}>
                  <i className='fas fa-play' />
                </LinkButton>
              </Tooltiped>
              <DownloadButton href={media} />
              <CopyButton text={media} />
            </ButtonGroup>
          </Stack>
          <InputComponent text='Version'>
            <Form.Control value={data.version} readOnly />
          </InputComponent>
        </Stack>
      </Card.Body>
    </Card>
  );
}

const _hiddenColumns = new Set(['url', 'uri']);

function Playlists({ playlists, title }) {
  if (playlists.length === 0) {
    return null;
  }

  const columns = allColumns(playlists).filter((k) => !_hiddenColumns.has(k.toLowerCase()));

  return (
    <CCard
      title={title}
      border='primary'
      open
      rightHeader={
        <Badge bg='primary' className='float-end'>
          {playlists.length}
        </Badge>
      }>
      <Table size='sm' hover>
        <thead>
          <tr>
            <th>Actions</th>
            {columns.map((k) => (
              <th key={k}>{k}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {playlists.map((p, i) => (
            <tr key={i}>
              <td>
                <ButtonGroup size='sm'>
                  <AnalysisButton as={Link} to={`/m3u8/media${window.location.search}&m3u8=${encodeURIComponent(p.url)}`} />
                  <CopyButton text={p.url} />
                </ButtonGroup>
              </td>
              {columns.map((k) => (
                <td key={k}>{p[k]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </CCard>
  );
}

function M3u8Master() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resp, setResp] = useState(null);
  const { media } = useQueryParams();

  useEffect(() => {
    httpGet(`/api/hls?m3u8=${encodeURIComponent(media)}`, setResp, setError, () => setLoading(false));
  }, [media]);

  return (
    <Container>
      {loading && <Spinner variant='primary' />}
      {error && <Alert variant='danger'>{error}</Alert>}
      {resp && (
        <Stack gap='2'>
          <Header data={resp.data} />
          <HTTPHeaders headers={resp.headers} />
          <RawPlaylist playlist={resp.raw} />
          <TableCard objects={resp.data.session_keys} title='Session keys' border='success' />
          <MissingStreams playlists={resp.data.playlists} medias={resp.data.medias} />
          <Playlists playlists={resp.data.playlists} title='Playlists' />
          <Playlists playlists={resp.data.medias} title='Medias' />
        </Stack>
      )}
    </Container>
  );
}

export default M3u8Master;
