import React, { useEffect, useState } from 'react';
import { Alert, Badge, ButtonGroup, Card, Container, Form, Spinner, Stack, Table } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';

import CheckChunkTimeComponent from '../Components/CheckChunkTimeComponent';
import { AnalysisButton, CopyButton, DownloadButton, LinkButton, Tooltiped } from '../Components/CommonButtons';
import CCard, { HTTPHeaders, RawPlaylist, TableCard } from '../Components/CommonCards';
import InputComponent from '../Components/InputComponent';
import Paginate, { Paginator } from '../Components/Paginate';
import { buildQuery, httpGet, showPlayButton, useQueryParams } from '../utils';

const ITEMS_PER_PAGE = 55;

function Header({ data }) {
  const { asset, media, m3u8, format, t0, DRMServiceId, licenseVersion } = useQueryParams();
  const linksQuery = buildQuery({
    media,
    format,
    t0,
    DRMServiceId,
    licenseVersion,
    asset,
  });

  return (
    <Card border='success'>
      <Card.Header>
        HLS media playlist & segments - <b className='text-primary'>{asset}</b>
      </Card.Header>
      <Card.Body>
        <Stack gap='2'>
          <Stack direction='horizontal' gap='1'>
            <InputComponent text='Master playlist URL'>
              <Form.Control value={media} readOnly />
            </InputComponent>
            <ButtonGroup>
              <Tooltiped text='play stream'>
                <LinkButton to={`/player?${linksQuery}`} disabled={!showPlayButton(media)}>
                  <i className='fas fa-play' />
                </LinkButton>
              </Tooltiped>
              <AnalysisButton as={Link} to={`/m3u8/master?${linksQuery}`} />
              <DownloadButton href={media} />
              <CopyButton text={media} size='md' />
            </ButtonGroup>
          </Stack>
          <Stack direction='horizontal' gap='1'>
            <InputComponent text='Media playlist URL'>
              <Form.Control value={m3u8} readOnly />
            </InputComponent>
            <ButtonGroup>
              <DownloadButton href={m3u8} />
              <CopyButton text={m3u8} size='md' />
            </ButtonGroup>
          </Stack>
          <InputComponent text='Version'>
            <Form.Control value={data.version} readOnly />
          </InputComponent>
          <InputComponent text='Target duration'>
            <Form.Control value={data.target_duration} readOnly />
          </InputComponent>
          {data.media_sequence && (
            <InputComponent text='Media sequence'>
              <Form.Control value={data.media_sequence} readOnly />
            </InputComponent>
          )}
          {data.independent_segments && (
            <InputComponent text='Independent segment'>
              <Form.Control value={data.independent_segments} readOnly />
            </InputComponent>
          )}
          {data.program_date_time && (
            <InputComponent text='Program date time'>
              <Form.Control value={data.program_date_time} readOnly />
            </InputComponent>
          )}
          {data.playlist_type && (
            <InputComponent text='Playlist type'>
              <Form.Control value={data.playlist_type} readOnly />
            </InputComponent>
          )}
          <InputComponent text='Timeshift buffer depth'>
            <Form.Control value={`${data.segments.reduce((a, b) => a + b.duration, 0).toFixed(3)} seconds`} readOnly />
          </InputComponent>
        </Stack>
      </Card.Body>
    </Card>
  );
}

function AdSections({ ads }) {
  if (ads.length === 0) {
    return null;
  }

  return (
    <CCard
      border='primary'
      title='Ad sections'
      rightHeader={
        <Badge bg='primary' className='float-end'>
          {ads.length}
        </Badge>
      }>
      <Stack gap='1'>
        <Paginator len={10}>
          {ads.map((ad) => (
            <CCard
              key={ad.raw}
              border='dark'
              header={
                <>
                  <Badge bg='primary'>{ad.cue_type}</Badge>
                  <Badge bg='dark'>{ad.duration}</Badge>
                  <Badge bg='success'>{new Date(ad.date).toLocaleString()}</Badge>
                  {ad.attributes.map((attr, i) => (
                    <Badge key={i} bg='info'>
                      {attr.message}
                    </Badge>
                  ))}
                </>
              }>
              <Table size='sm' hover>
                <thead>
                  <tr>
                    <th>Message</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {ad.attributes.map((attr, i) => (
                    <tr key={i}>
                      <td>{attr.message}</td>
                      <td>{attr.duration}</td>
                    </tr>
                  ))}
                  <tr></tr>
                </tbody>
              </Table>
              <ReactJson src={ad.payload} collapsed={1} displayDataTypes={false} theme='monokai' style={{ fontSize: 'medium' }} />
              <span>{ad.raw}</span>
            </CCard>
          ))}
        </Paginator>
      </Stack>
    </CCard>
  );
}

function Segments({ segments, isVod }) {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');

  const start = (page - 1) * ITEMS_PER_PAGE;
  const end = start + ITEMS_PER_PAGE;
  const filteredSegments = segments.filter((s) => s.url.indexOf(filter) !== -1);
  const slice = filteredSegments.slice(start, end);

  useEffect(() => {
    setPage(1);
  }, [filteredSegments.length]);

  return (
    <CCard
      title='Segments'
      border='primary'
      open
      rightHeader={
        <Badge bg='primary' className='float-end'>
          {filteredSegments.length}
        </Badge>
      }>
      <Stack gap='3'>
        <InputComponent icon='fas fa-search'>
          <Form.Control placeholder='search...' onChange={(evt) => setFilter(evt.target.value)} />
        </InputComponent>
        {filteredSegments.length === 0 ? (
          <Alert variant='warning'>No segment found</Alert>
        ) : (
          <Table size='sm' hover>
            <thead>
              <tr>
                {isVod ? (
                  <>
                    <th>URI</th>
                    <th className='text-end'>Offset</th>
                    <th className='text-end'>Size</th>
                    <th className='text-end'>Duration</th>
                  </>
                ) : (
                  <>
                    <th>Actions</th>
                    <th>URI</th>
                    <th>Start time</th>
                    <th>Duration</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {isVod
                ? slice.map((s, i) => (
                    <tr key={i}>
                      <td>{s.uri}</td>
                      <td className='text-end'>{s.offset}</td>
                      <td className='text-end'>{s.size}</td>
                      <td className='text-end'>{s.duration}</td>
                    </tr>
                  ))
                : slice.map((s) => (
                    <tr key={s.url}>
                      <td>
                        <ButtonGroup size='sm'>
                          <DownloadButton href={s.url} />
                          <CopyButton text={s.url} />
                        </ButtonGroup>
                      </td>
                      <td>{s.uri}</td>
                      <td>{new Date(s.date).toLocaleString()}</td>
                      <td>{s.duration}</td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        )}
      </Stack>
      <Paginate len={ITEMS_PER_PAGE} total={filteredSegments.length} page={page} onChange={setPage} />
    </CCard>
  );
}

function M3u8Chunks() {
  const { m3u8 } = useQueryParams();
  const [requestTime, setRequestTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resp, setResp] = useState(null);

  useEffect(() => {
    setRequestTime(Date.now());
    httpGet(`/api/hls?m3u8=${encodeURIComponent(m3u8)}`, setResp, setError, () => setLoading(false));
  }, [m3u8]);

  const isVod = !!(resp?.data?.playlist_type === 'VOD');

  return (
    <Container>
      {loading && <Spinner variant='primary' />}
      {error && <Alert variant='danger'>{error}</Alert>}
      {resp && (
        <Stack gap='2'>
          <Header data={resp.data} />
          <HTTPHeaders headers={resp.headers} />
          <RawPlaylist playlist={resp.raw} />
          <TableCard
            objects={resp.data.keys}
            title='Keys'
            border='success'
            hookOnKeys={{ 'Start time': (st) => new Date(st).toLocaleString() }}
          />
          {resp.data.segments.length > 0 && !isVod && (
            <CheckChunkTimeComponent requestTime={requestTime} lastChunkTime={new Date(resp.data.segments.at(-1).date).getTime()} />
          )}
          <AdSections ads={resp.data.sctes} />
          <TableCard objects={resp.data.dateranges} title='Date ranges' hiddenColumns={['X-COM-COMPOSER-BEACONS']} />
          <Segments segments={resp.data.segments} isVod={isVod} />
        </Stack>
      )}
    </Container>
  );
}

export default M3u8Chunks;
